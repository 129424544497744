var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.locales && _vm.locales.length > 1
    ? _c("v-select", {
        style: _vm.$vuetify.breakpoint.smAndDown
          ? "max-width: 70px;"
          : "max-width: 120px;",
        attrs: {
          flat: "",
          solo: "",
          "hide-details": "",
          "append-icon": "",
          "background-color": "transparent px-0",
          color: "#000000",
          items: _vm.items
        },
        on: { input: _vm.changedLabel },
        scopedSlots: _vm._u(
          [
            {
              key: "selection",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "span",
                    {
                      class: _vm.textClass + " " + _vm.positionClass,
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c(
                        "strong",
                        [
                          _c("v-icon", { class: _vm.textClass }, [
                            _vm._v("language")
                          ]),
                          _vm.$vuetify.breakpoint.smAndDown
                            ? [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      item.value.toUpperCase()
                                    )
                                  }
                                })
                              ]
                            : [
                                _c("span", {
                                  domProps: { textContent: _vm._s(item.text) }
                                })
                              ]
                        ],
                        2
                      )
                    ]
                  )
                ]
              }
            }
          ],
          null,
          false,
          2635562206
        ),
        model: {
          value: _vm.currentValue,
          callback: function($$v) {
            _vm.currentValue = $$v
          },
          expression: "currentValue"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }