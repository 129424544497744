<template>
  <v-content>
    <v-toolbar class="landing-toolbar" fixed style="z-index:2;">
      <v-layout row wrap>
        <v-flex>
          <v-container py-0>
            <v-layout row wrap>
              <v-flex xs12 lg10 xl9>
                <v-layout row align-center>
                  <v-flex v-if="showStarlingLogo" shrink class="d-flex">
                    <img :src="'/svg/starling-logo-colors.svg'" alt="" style="height:40px;max-width:100px">
                  </v-flex>
                  <v-flex v-if="showStarlingLogo && showPartnerLogo" shrink mx-2>
                    <v-divider vertical inset color="darkgrey" class="d-block ma-0" style="height:34px"/>
                  </v-flex>
                  <v-flex v-if="showPartnerLogo" shrink>
                    <template v-if="partner.logo.linkUrl">
                      <a :href="partner.logo.linkUrl" target="_blank" class="d-flex">
                        <img :src="partner.logo.imageUrl" alt="" style="height:40px" :style="{ 'max-width': partnerLogoWidth }">
                      </a>
                    </template>
                    <template v-else>
                      <img :src="partner.logo.imageUrl" alt="" style="height:40px" class="d-flex" :style="{ 'max-width': partnerLogoWidth }">
                    </template>
                  </v-flex>

                  <v-spacer></v-spacer>

                  <v-flex class="shrink">
                    <localeSwitcher v-on:input="refreshChildComponents" text-class="primary--text" position-class="d-flex justify-right" style="float:right; text-align: end;"/>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-toolbar>

    <div class="landing-content fill-height" :class="{ 'mobile': $vuetify.breakpoint.smAndDown, 'with-footer': showFooter }">
      <router-view :key="refreshKey"
                    class="white"
                    :elevation="$vuetify.breakpoint.xsOnly?0:8"
                    :card-height="500"
                    :card-styles="$vuetify.breakpoint.xsOnly? 'margin-top:90px;' : ''"></router-view>
    </div>

    <v-footer absolute v-if="showFooter" class="landing-footer" :style="`background-image: url('${backgroundUrl}');`">
      <v-layout column fill-height pa-3 pb-2>
        <v-flex>
          <v-layout row wrap>
            <v-flex xs12 text-center mb-2 v-if="partner && partner.type === 'COLABELLED'">
              <p class="starling-body text-italic" style="color:white!important;"> {{ $t('common.partnership.powered_by') }} </p>
              <starling-logo style="max-width:200px;"></starling-logo>
            </v-flex>
            <v-flex xs12 text-center mb-2 :class="{ 'py-2': !partner || partner.type !== 'COLABELLED' }">
              <h2 class="starling-h2 white--text">
                {{ $t('public.landing.content.having_questions.title') }}
              </h2>
            </v-flex>
            <v-flex xs12 text-center>
              <div class="starling-body white--text" v-html="$t('public.landing.content.having_questions.description')"></div>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-spacer class="fill-height"></v-spacer>
        <maintenance-bar v-if="maintenance" rounded></maintenance-bar>

        <v-flex text-center>
          <p class="caption white--text mb-0">© {{ new Date().getFullYear() }} Starling Minds™ Inc.</p>
        </v-flex>
      </v-layout>
    </v-footer>
  </v-content>
</template>

<script>
import localeSwitcher from '@/views/components/localeSwitcher/locale-switcher.vue';
import { mapState } from 'vuex';
import StarlingLogo from '@/views/images/starling-logo';
import MaintenanceBar from '@/views/components/utilities/maintenance-bar.vue';

export default {
  name: 'landing',
  components: { MaintenanceBar, StarlingLogo, localeSwitcher },
  data() {
    return {
      refreshKey: 0,
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
    }),
    backgroundUrl() {
      return this.partner?.theme?.images?.BOTTOM_NAV?.imageUrl || '/svg/footer-background.svg';
    },
    showStarlingLogo() {
      return !this.partner || this.partner.type === 'BASIC' || this.partner.type === 'COBRANDED';
    },
    showPartnerLogo() {
      return this.partner?.logo?.imageUrl;
    },
    partnerLogoWidth() {
      if (this.showStarlingLogo) {
        return this.$vuetify.breakpoint.lgAndDown ? '100px' : '130px';
      }
      return this.$vuetify.breakpoint.lgAndDown ? '200px' : '260px';
    },
    showFooter() {
      return this.$route.meta.footer === 'blue';
    },
    maintenance() {
      return this.$store.getters.maintenance;
    },
  },
  methods: {
    refreshChildComponents(lang, shouldRefresh) {
      this.refreshKey++;
    },
  },
};
</script>

<style lang="scss">
.landing-toolbar {
  padding-top: var(--safe-area-top);
  transform: translateZ(1px) !important;

  &.v-toolbar {
    background-color: white;
  }
}
.landing-content {
  padding-top: calc(64px + var(--safe-area-top));

  &.mobile {
    padding-top: calc(56px + var(--safe-area-top));
  }

  &.with-footer {
    padding-bottom: 350px;
  }
}
.landing-footer {
  min-height: 350px;
  max-height: 350px;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  a {
    color: inherit;
  }
}
</style>
