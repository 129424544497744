<template>
  <v-select v-if="locales && locales.length > 1"
            flat solo hide-details
            append-icon=""
            background-color="transparent px-0"
            color="#000000"
            :style="$vuetify.breakpoint.smAndDown ? 'max-width: 70px;':'max-width: 120px;'"
            v-model="currentValue" :items="items" @input="changedLabel">
    <template v-slot:selection="{ item }">
    <span :class="textClass + ' '+ positionClass" style="width: 100%;">
        <strong>
          <v-icon :class="textClass">language</v-icon>
          <template v-if="$vuetify.breakpoint.smAndDown"><span v-text="item.value.toUpperCase()"></span></template>
          <template v-else><span v-text="item.text"></span></template>
        </strong>
      </span>
    </template>
  </v-select>
</template>

<script>
import { setLanguage } from '@/scripts/lang';

export default {
  name: 'locale-switcher',
  props: {
    textClass: {
      type: String,
      required: false,
      default: 'white--text',
    },
    positionClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      locales: null,
      currentValue: null,
      items: [],
      valid: true,
      isWatched: false,
      language: null,
      interval: null,
    };
  },
  watch: {
    '$store.getters.currentLanguage'() {
      this.currentValue = localStorage.getItem('currLang');
      setLanguage(this.currentValue);
      this.interval = window.setTimeout(() => {
        this.changedLabel();
      }, 100);
    },
  },
  mounted() {
    this.$store.dispatch('identity/locales').then(res => {
      this.locales = res;
      this.locales.forEach(locale => {
        const item = { text: locale.name, value: locale.language };
        this.items.push(item);
      });
      if (localStorage.getItem('currLang') && !this.locales.find(l => l.language === localStorage.getItem('currLang'))) localStorage.removeItem('currLang');

      const filteredNavigatorLanguage = this.items.filter(item => item.value === navigator.language.substr(0, 2));
      const navigatorLanguage = filteredNavigatorLanguage.length ? filteredNavigatorLanguage[0].value : null;
      const localLanguage = navigatorLanguage || 'en';

      if (!localStorage.getItem('currLang')) localStorage.setItem('currLang', localLanguage);

      this.language = localStorage.getItem('currLang') ? localStorage.getItem('currLang') : localLanguage;
      this.currentValue = localStorage.getItem('currLang');
      setLanguage(this.currentValue);
    });
  },
  methods: {
    changedLabel() {
      localStorage.setItem('currLang', this.currentValue);
      setLanguage(this.currentValue);
      this.$store.commit('removeNotification');
      this.$emit('input', this.currentValue, this.isWatched);
    },
  },
};
</script>
